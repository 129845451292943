.envitech-web-app {
  display: flex;
  flex-direction: row-reverse;
  background-color: #36393f;
}

.monitor-types-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 0 auto;
  gap: 1rem;
}

.monitor-list {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: max-height 0.15s;
  max-height: 0;
}

.monitor-list.expanded {
  max-height: 100vh;
  transition: max-height 0.25s ease-in;
}

.data-input-selector {
  color: rgb(218, 214, 214);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(82, 83, 87);

  padding: 1rem;
  height: 150px;
  width: 300px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.data-input-selector .title {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.data-input-selector .default-data-button {
  all: unset;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #19191a;
  border-radius: 8px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .data-input-selector {
    height: 100%;
    width: 100%;
    padding: 0;
    justify-content: center;
    gap: 1rem;
  }
}
