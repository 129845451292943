.legend-background {
  background-color: rgba(124, 127, 134, 0.692);
  width: 100%;
  height: 100%;
  position: absolute;
}

.monitor-legend {
  background-color: rgb(82, 83, 87);
  color: white;

  border-radius: 8px;
  padding: 1rem 2rem 1rem 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.legend-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.legend-property {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.25rem;
}

.legend-property:not(:first-child) {
  margin-top: 0.5rem;
}

.legend-close-button {
  all: unset;
  margin-top: 1.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #383a3d;
  border-radius: 8px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .monitor-legend {
    height: 100%;
    width: 100%;
    padding: 0;
  }

  .legend-close-button {
    margin-top: auto;
    margin-bottom: 1rem;
  }
}
